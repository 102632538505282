import 'intersection-observer';
import '../styles/Globals.scss';
import * as gtag from 'services/gtag';
import 'i18n';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/node';
import { SETTINGS } from 'config/constants';
import * as gtm from 'services/gtm';

export function reportWebVitals({ id, name, label, value }) {
  gtag.event({
    action: name,
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    event_label: id,
    non_interaction: true
  });
}

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: 'https://f494d134a1abba98b66476861b8ccf60@sentry.eunoia.es/9'
});

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = url => {
      gtag.pageview(url);
      gtm.pageview(url);
    };
    if (localStorage.getItem(SETTINGS.COOKIES_ID_ANALYTICS)) {
      router.events.on('routeChangeComplete', handleRouteChange);
    }

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Component {...pageProps} />
    </>
  );
}
