export const SEOConstants = {
  title: 'Eunoia Digital | Consultora digital en Barcelona',
  author: 'Eunoia Digital',
  copyright: 'Todos los derechos reservados.',
  keywords: '',
  robots: 'index',
  ogType: 'website',
  url: 'https://www.eunoia.es',
  image: 'https://www.eunoia.es/images/meta/base.webp',
  description:
    'Consultora Eunoia Digital. Especializada en transformación digital. Reinventamos tu empresa para un futuro digital a través de la experiencia de usuario'
};

export const SETTINGS = {
  CONTACT_EMAIL: 'info@eunoia.es',
  BLOG_PAGE_SIZE: 10,
  COOKIES_ID_ANALYTICS: 'GA_Accepted',
  COOKIES_ID_MAPS: 'Maps_Accepted'
};

export const CMSConstants = {
  BASE_URL: 'https://strapi.eunoia.es'
  // BASE_URL: 'http://localhost:1337'
};
