export const GTM_TRACKING_ID = 'GTM-M2PHKJTS';

// Esta función ahora acepta cualquier tipo de argumento de url.
export const pageview = url => {
  // No hay necesidad de verificar la existencia de window.dataLayer en JavaScript.
  // Simplemente ejecutamos la función sin ninguna comprobación.

  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'pageview',
      page: url
    });
  } else {
    console.log({
      event: 'pageview',
      page: url
    });
  }
};
