import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import TranslationES from 'assets/language/es.json';
import TranslationCA from 'assets/language/cat.json';
import TranslationEN from 'assets/language/en.json';

i18n
  .use(initReactI18next)
  .use(detector)
  .init({
    fallbackLng: 'es',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: true,
      formatSeparator: ','
    },
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true
    },
    resources: {
      es: {
        translations: TranslationES
      },
      ca: {
        translations: TranslationCA
      },
      en: {
        translations: TranslationEN
      }
    }
  });

export default i18n;
